.sk-pagination-navigation {
  .sk-toggle {
    max-height: 50px;
    .sk-toggle-option {
      width: 50px;
      height: 50px;
      border: none;
      line-height: 40px;
      font-size: 16px;
    }
  }
}

.sk-pagination {
  max-width: 100%;
  .sk-toggle {
    display: flex;
    justify-content: space-evenly;
    .sk-toggle-option {
      color: #ea5d4e;
      border: none;
      background: none;
      padding: 6px;
      max-width: 30px;
      text-decoration: underline;
    }
    .sk-toggle-option.is-active {
      background-color: #eef2f3;
      border-radius: 30px;
      text-decoration: none;
    }
    .sk-toggle-option.is-disabled {
      text-decoration: none;
    }
    .sk-toggle-option:first-child,
    .sk-toggle-option:last-child {
      text-decoration: none;
      padding: 6px 12px;
      max-width: 100px;
      border: 1px solid #cdd7da;
      border-radius: 30px;
    }
  }
}

.sk-pagination-stats {
  margin-top: 15px;
  color: #193e72;
  .sk-toggle {
    .sk-toggle-option {
      color: #ea5d4e;
      border: none;
      background: none;
      padding: 6px;
      max-width: 30px;
      text-decoration: underline;
      margin-right: 3px;
    }
    .sk-toggle-option.is-active {
      background-color: #eef2f3;
      border-radius: 30px;
      text-decoration: none;
    }
  }
}

.sk-panel {
  margin-bottom: 15px;
  .sk-panel__header {
    font-size: 16px;
    color: #193e72;
    font-weight: bold;
  }
  .sk-item-list-option__text {
    color: #000000;
    flex-basis: 150px;
    flex-grow: 1;
    overflow: hidden;
    padding-right: 3px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .sk-item-list-option__count {
    color: #46565d;
    margin-left: auto;
  }
  .sk-item-list-option.is-active {
    .sk-item-list-option__text {
      color: #ea5d4e;
    }
  }
}

.sk-selected-filters-option {
  background: none;
  padding: 5px 0 5px 10px;
  font-size: 12px;
  line-height: 18px;
  border: 1px solid #dde4e7;
  .sk-selected-filters-option__name,
  .sk-selected-filters-option__remove-action {
    font-size: 12px;
    color: #495075;
  }
}

.sk-range-slider {
  .rc-slider-handle {
    border-color: #ea5d4e;
    background-color: #ffffff;
  }
  .rc-slider-track {
    background-color: #ea5d4e;
  }
  .rc-slider-mark-text {
    color: #46565d;
  }
}

.sk-refinement-list__view-more-action {
  color: #193e72;
}

.sk-no-hits {
  margin-top: 30px;
  .sk-no-hits__info {
    font-size: 18px;
    font-weight: bold;
    color: #ea5d4e;
  }
}

a.sk-toggle-option__text {
  color: #ea5d4e;
}
