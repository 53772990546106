.container {
    position: relative;
    min-width: 190px;
}

.button {
    background: #fff;
    border-radius: 20px;
    border: 1px solid #ced4da;
    padding: 7px 10px 3px;
    width: 100%;
    text-align: left;
    span {
        color: #718c98;
        display: inline-block;
        margin: 0 5px;
        font-size: 12px;
    }
    svg {
        float: right;
        color: #ea5d4e;
    }
}

.popout-container {
    position: absolute;
    top: 70px;
    z-index: 100;
    width: 500px;
    background: #fff;
    border-radius: 20px;
    border: 1px solid #ced4da;
    padding: 10px 15px 15px;
    text-align: right;

    .close-btn {
        font-weight: bold;
        font-size: 20px;
        line-height: 20px;
    }
}

.calendar-container {
    margin: 0;
    padding: 0;
    label {
        width: 100%;
        text-align: center;
        display: inline-block;
        margin: 5px 0 0;
    }
    &:last-of-type {
        border-left: 1px solid #ced4da;
    }
    &:first-of-type {
        padding-left: 0;
    }
}

.calendar {
    border: none;
    font-family: inherit;
    font-size: inherit;
    background: none;
    color: #193e72;
}

:global .react-datepicker__month-text {
    padding: 3px 10px 0;
    &:hover {
        background: none;
    }
    &.react-datepicker__month--in-range,
    &.react-datepicker__month--in-range:hover {
        background: none;
        color: #193e72;
    }
    &.react-datepicker__month--selected,
    &.react-datepicker__month--range-start,
    &.react-datepicker__month--range-end {
        background-color: #ea5d4e;
        color: #fff;
        font-weight: bold;
        border-radius: 15px;
        &:hover {
            background: #ced4da;
            color: #193e72;
        }
    }
}

:global .react-datepicker__header {
    color: #193e72;
    background: none;
    border: none;
}

:global .react-datepicker__navigation {
    &.react-datepicker__navigation--next {
        border-left-color: #ea5d4e;
    }
    &.react-datepicker__navigation--previous {
        border-right-color: #ea5d4e;
    }
}
