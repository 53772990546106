.btn {
  padding: 0.375rem 2.5rem;
}
.btn-primary,
.btn-primary:hover {
  color: #fff;
  background-color: #46b5d6;
  border-color: #46b5d6;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none;
}
.btn-secondary {
  background-color: #ea5d4e;
  color: #ffffff;
  border: none;
  padding: 7px 35px 5px;
  font-weight: bold;
  border-radius: 38px;
}
.btn-link {
  background-color: transparent;
  color: #193e72;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
}
.fa-disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
