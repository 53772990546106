.beta-label {
  color: currentColor;
  margin-bottom: 0;

  strong {
    background-color: #46a86c;
    color: #ffffff;
    display: inline-block;
    font-weight: bold;
    margin-right: 5px;
    padding: 0.1em 0.5em;
    text-transform: uppercase;
  }

  button {
    appearance: none;
    background: none;
    border: 0px;
    color: currentColor;
    padding: 0;
    text-decoration: underline;

    &:focus,
    &:hover {
      text-decoration: none;
    }
  }
}
