*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body {
    width: 100%;
    height: 100%;
    background-image: url(../img/background.png);
    background-repeat: repeat-y;
    background-size: cover;
}
#root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
