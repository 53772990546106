:export {
  barColour: #ea5d4e;
  selectedBarColor: #193e72;
  labelColour: #46565d;

  geoBorderColor: #000000;
  geoBackground: #ffffff;
  geoBackground6: #999999;
  geoBackground5: #acbcc3;
  geoBackground4: #bccace;
  geoBackground3: #ccd7da;
  geoBackground2: #dde4e7;
  geoBackground1: #eef2f3;
  selectedGeoBackground: #193e72;

  treeMapStroke: #ffffff;
  treeMapStrokeSelected: #193e72;
  treeMapBackground1: #ea5d4e;
  treeMapBackground2: #f29330;
  treeMapBackground3: #fed47a;
  treeMapBackground4: #6667ad;
  treeMapBackground5: #2ea9b0;
  treeMapBackground6: #46a86c;
  treeMapBackground7: #acbcc3;
}

.treeMapCount {
  font-size: 10px;
  font-weight: bold;
  cursor: default;
}

.treeMapKey {
  font-size: 8px;
  cursor: default;
}

.chart-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 60px;
}

.chart {
  z-index: 0;
  position: relative;
  padding: 15px;
  margin-bottom: 15px;
  border: 2px solid #cdd7da;
  border-radius: 15px;
  .icon-expanded,
  .icon-collapsed,
  .icon-download {
    z-index: 1;
    position: absolute;
    right: 10px;
    top: 10px;
    border: none;
    background: none;
    color: #193e72;
  }
  .icon-download {
    right: 30px;
  }
}

.chart-expanded {
  position: absolute;
  background: #fff;
  left: 10;
  top: 233;
  border: 2px solid #cdd7da;
  border-radius: 15px;
  z-index: 100;
}
