.detail-page {
  display: inline-flex;

  @media (min-width: 768px) {
    margin: 0 30px;
  }
  .toc {
    position: relative;
    border-radius: 5px;
    margin: 15px;
    width: 250px;
    @media (max-width: 1023px) {
      display: none;
    }
    h4 {
      color: #acbcc3;
      font-size: 14px;
      margin-bottom: 20px;
    }
    ul {
      list-style: none;
      padding: 20px;
      background-color: #eef2f3;

      li {
        margin-bottom: 10px;
        button {
          color: #193e72;
          font-weight: bold;
          font-size: 18px;
        }
      }
    }
    .toc-content {
      padding-top: 15px;
      position: sticky;
      top: 0;
    }
  }
  .content {
    margin: 15px;
    @media (min-width: 1024px) {
      width: 80%;
    }
    .meta-detail {
      font-size: 14px;
      color: #7f8b90;
      margin: 0;
      dt {
        font-weight: normal;
        margin-right: 7px;
      }
      dd {
        font-weight: bold;
      }
      dt,
      dd {
        display: inline-block;
        line-height: 15px;
      }
      dd:after {
        content: '\007C';
        margin: 10px;
      }
      dd:last-of-type:after {
        content: none;
      }
    }
    .detail-container {
      border: 2px solid #cdd7da;
      border-radius: 5px;
      margin: 0 0 50px;
      padding: 15px;
      dl.key-facts {
        columns: 1;
        @media (min-width: 768px) {
          columns: 3;
        }
      }
      dl.external-links {
        margin: 0;
        a {
          color: black;
          word-break: break-all;
        }
        dt {
          margin-right: 10px;
        }
      }
      dt {
        font-size: 12px;
        color: #7f8b90;
        text-transform: uppercase;
        font-weight: bold;
      }
      dd {
        break-before: avoid;
        font-size: 14px;
      }
    }
  }
}

.back-bar {
  background: #eef2f3;
  padding: 30px 10px 20px 10px;
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  @media (max-width: 1024px) {
    justify-content: left;
  }
  .back-button {
    background-color: #ea5d4e;
    color: #ffffff;
    border: none;
    border-radius: 38px;
    height: 38px;
    padding: 10px;
    margin: 12px 0;
    font-weight: bold;
    font-size: 14px;
    @media (max-width: 1024px) {
      order: 3;
      margin-left: 5px;
    }
  }
  .searchbox-container {
    height: 100%;
    border-radius: 54px;
    box-shadow: 1px 1px 5px 2px #cdd7da;
    min-width: 300px;
    width: 50%;
    @media (max-width: 1024px) {
      width: 100%;
    }
  }
}

.link-widget {
  margin-right: 1rem;
  word-break: break-all;
}
.content-buttons {
  text-align: right;
}
.flag-button,
.download-button {
  background-color: white;
  border: none;
  color: #eb5757;
  margin: 0;
  font-size: 17px;
  font-weight: bold;
}
.download-button {
  color: #193e72;
}
h3 {
  font-weight: bold;
}

.content-field {
  @media (min-width: 768px) {
    background-image: url('../img/content-item-bullet.svg');
    background-repeat: no-repeat;
    background-size: 100px 100px;
    background-position: -25px -25px;
    padding-left: 75px;
    padding-bottom: 35px;
  }
}

.no-hits {
  padding: 30px 50px;
}
