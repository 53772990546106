.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  &:focus {
    outline: 0;
    box-shadow: none;
  }
}

.sk-select select {
  @extend .form-control;
}

@mixin vertical-input-sizer() {
  display: inline-grid;
  vertical-align: top;
  align-items: center;
  position: relative;
  align-items: stretch;

  &::after,
  input,
  textarea {
    width: auto;
    min-width: 1em;
    grid-area: 2 / 1;
    font: inherit;
    background: none;
    appearance: none;
    border: none;
    overflow: visible;
    white-space: pre-wrap;
  }

  &::after {
    content: attr(data-value) ' ';
    visibility: hidden;
    margin-right: 18px;
    white-space: pre-wrap;
  }
}