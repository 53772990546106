.content {
  padding: 50px 50px;
}

.search-area {
  background: #eef2f3;
  padding: 25px 15px 15px 15px;
  color: #193e72;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  .searchbox-container {
    height: 100%;
    border-radius: 58px;
    box-shadow: 1px 1px 5px 2px #cdd7da;
    width: 50%;
    @media (max-width: 900px) {
      width: 100%;
    }
  }
}
