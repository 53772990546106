@font-face {
  font-family: 'NeuzitGrotesk';
  src: url('../fonts/neuzeitgro-reg-webfont.eot');
  src: url('../fonts/neuzeitgro-reg-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/neuzeitgro-reg-webfont.woff2') format('woff2'),
    url('../fonts/neuzeitgro-reg-webfont.woff') format('woff'), url('../fonts/neuzeitgro-reg-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NeuzitGrotesk';
  src: url('../fonts/neuzeitgro-bol-webfont.eot');
  src: url('../fonts/neuzeitgro-bol-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/neuzeitgro-bol-webfont.woff2') format('woff2'),
    url('../fonts/neuzeitgro-bol-webfont.woff') format('woff'), url('../fonts/neuzeitgro-bol-webfont.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: NeuzitGrotesk;
}

h1,
h2,
h3 {
  color: #193e72;
}

p,
li {
  color: #000000;
}

.underline {
  text-decoration: underline;
}
