.facets {
  background: #eef2f3;
  margin: 15px 15px 30px 0;
  padding: 15px 15px 35px 15px;
  @media (min-width: 768px) {
    min-width: 168px;
    padding-bottom: 15px;
  }
  .filter-heading {
    color: #46565d;
    font-weight: bold;
    font-size: 12px;
  }
  .clear-filters {
    padding: 0;
    margin: 0;
    font-size: 12px;
    .clear-filters-text {
      color: #193e72;
    }
    .clear-filters-text-disabled {
      color: #46565d;
    }
    cursor: pointer;
  }
  .facet-title {
    color: #193e72;
    font-weight: bold;
  }
}

:global .sort-order-button {
  border: none;
  background: none;
  color: #193e72;
}
