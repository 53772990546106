.lozenge {
  padding: 8px 12px;
  border-radius: 28px;
  background-color: #eef2f3;
  color: #193e72;
}
.phase-1 {
  background-color: #dde4e7;
  color: #ffffff;
}
.phase-2 {
  background-color: #cdd7da;
  color: #ffffff;
}
.phase-3 {
  background-color: #bccace;
  color: #ffffff;
}
.phase-4 {
  background-color: #acbcc3;
  color: #ffffff;
}
.status-completed,
.status-complete,
.status-complete-follow-up-complete {
  background-color: #2ea9b0;
  color: #ffffff;
}
.status-terminated,
.status-stopped,
.status-prematurely-ended,
.status-stopped-early-terminated {
  background-color: #ea5d4e;
  color: #ffffff;
}
.status-withdrawn,
.status-withheld {
  background-color: darken(#ea5d4e, 20);
  color: #ffffff;
}
.status-active-not-recruiting,
.status-ongoing {
  background-color: #46a86c;
  color: #ffffff;
}
.status-recruiting {
  background-color: #f29330;
  color: #ffffff;
}
.status-not-yet-recruiting {
  background-color: #6667ad;
  color: #ffffff;
}
.status-suspended,
.status-temporarily-halted {
  background-color: darken(#6667ad, 20);
  color: #ffffff;
}
.status-invitation {
  background-color: #fed47a;
  color: #193e72;
}
.status-not-available,
.status-planned {
  background-color: darken(#fed47a, 20);
  color: #193e72;
}
.status-other {
  background-color: #eef2f3;
  color: #193e72;
}
