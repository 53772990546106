.header {
  background-color: #193e72;
  flex-shrink: 0;
  border-bottom-left-radius: 100% 20%;
  border-bottom-right-radius: 100% 20%;
  z-index: 1;
  .header-container {
    justify-content: space-between;
    padding: 0 1rem;
    margin: 0 !important;
    height: 90px;

    .header-logo-container {
      flex-basis: 250px;
      flex-shrink: 0;
      flex-grow: 0;

      @media screen and (max-width: 599px) {
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
      }
    }

    .header-logo {
      height: 66px;
    }

    .header-beta {
      color: #ffffff;
      font-size: 14px;
      line-height: 1.3;
      margin-bottom: 0;
      text-align: right;

      @media screen and (min-width: 600px) and (max-width: 750px) {
        flex-basis: 270px;
        flex-grow: 0;
      }

      @media screen and (max-width: 599px) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 5px;
        text-align: center;
      }
    }

    @media screen and (min-width: 1024px) {
      padding: 0 8%;
    }

    @media screen and (min-width: 1300px) {
      padding: 0 16%;
    }

    @media screen and (max-width: 599px) {
      height: 110px;
    }
  }
}
