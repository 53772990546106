.dataset-list-home {
    list-style: none;
    padding: 0 15px;
    text-align: left;
    li {
        display: inline;
        margin-right: 30px;
        a {
            color: #ffffff;
            font-size: 18px;
            font-weight: bold;
            text-decoration: none;
        }
        a:hover {
            text-decoration: none;
        }
        a.active {
            border-bottom: 3px solid #ea5d4e;
        }
    }
}

.dataset-list {
    list-style: none;
    margin: 15px;
    padding: 0;
    text-align: left;
    flex: 1 1 auto;
    li {
        display: inline;
        margin-right: 25px;
        a {
            color: #495075;
            padding-bottom: 4px;
            text-decoration: none;
        }
        a:hover {
            text-decoration: none;
        }
        a.active {
            border-bottom: 3px solid #ea5d4e;
        }
    }
}
.item-count {
    margin: 0 10px 0 10px;
}
.loader {
    background-image: url('../img/loader.gif');
    background-size: 18px 18px;
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: text-bottom;
    margin-left: 5px;
}