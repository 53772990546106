.search-area {
  background: #eef2f3;
  padding: 25px 15px 15px 15px;
  color: #193e72;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  .searchbox-container {
    height: 100%;
    border-radius: 58px;
    box-shadow: 1px 1px 5px 2px #cdd7da;
    flex: 1 1 auto;
    width: 50%;
    @media (max-width: 1024px) {
      width: 100%;
    }
  }
  .toggle-details {
    height: 100%;
    margin-top: 20px;
    display: inline-flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    align-items: center;

    .switch {
      position: relative;
      display: inline-block;
      width: 30px;
      height: 17px;
      margin: 0 5px;
      padding-left: 1px;
      input {
        display: none;
      }
      .slider-round {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #acbcc3;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 16px;
      }
      .slider-round:before {
        position: absolute;
        content: '';
        height: 13px;
        width: 13px;
        left: 2px;
        bottom: 2px;
        background-color: #ffffff;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
      }
      input:checked + .slider-round {
        background-color: #193e72;
      }
      input:focus + .slider-round {
        box-shadow: 0 0 1px #193e72;
      }
      input:checked + .slider-round:before {
        -webkit-transform: translateX(13px);
        -ms-transform: translateX(13px);
        transform: translateX(13px);
      }
    }
  }
}
.results-area {
  margin-top: 15px;
  padding: 15px;
  display: flex;
  @media (max-width: 650px) {
    flex-wrap: wrap;
  }
  justify-content: space-around;
  .filters {
    min-width: 230px;
    width: 80%;
    @media (min-width: 799px) {
      width: 25%;
    }
  }
  .filters--hidden {
    @media (max-width: 650px) {
      display: none;
    }
  }
  .content {
    width: 100%;
    @media (min-width: 799px) {
      width: 70%;
    }
    .titleContainer {
      margin: 15px 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .results-page-title {
        color: #193e72;
        font-weight: bold;
      }
      .hit-stats {
        color: #ea5d4e;
        text-align: right;
      }
    }
    .tabs-sorting-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 15px;
      .sorting-selector {
        select {
          margin-right: 15px;
          color: #ea5d4e;
          border-radius: 38px;
          @media (max-width: 460px) {
            font-size: 14px;
          }
          @media (max-width: 376px) {
            font-size: 12px;
          }
        }
        &:after {
          margin-top: 0;
        }
      }
      .result-tabs {
        margin-bottom: 15px;
        background: #eef2f3;
        border: solid 1px #acbcc3;
        border-radius: 40px;
        a {
          color: #193e72;
        }
        .result-tab-left {
          background: #ffffff;
          border-radius: 40px 0 0 40px !important;
          svg {
            margin-right: 10px;
          }
          @media (max-width: 460px) {
            padding: 0.25rem;
            font-size: 14px;
            border-radius: 30px 0 0 30px !important;
          }
          @media (max-width: 376px) {
            font-size: 12px;
          }
        }
        .result-tab-right {
          background: #ffffff;
          border-radius: 0 40px 40px 0 !important;
          svg {
            margin-right: 10px;
          }
          @media (max-width: 460px) {
            padding: 0.25rem;
            font-size: 14px;
            border-radius: 0 30px 30px 0 !important;
          }
          @media (max-width: 376px) {
            font-size: 12px;
          }
        }
        svg {
          // TODO: Make this less grim
          @media (max-width: 850px) {
            display: none;
          }
          @media (max-width: 798px) {
            display: inline;
          }
          @media (max-width: 710px) {
            display: none;
          }
          @media (max-width: 650px) {
            display: inline;
          }
          @media (max-width: 300px) {
            display: none;
          }
        }
      }
    }
    .text-results {
      margin-bottom: 1rem;
      .pagination-container {
        max-width: 100%;
        @media (min-width: 452px) {
          margin: 0 25%;
        }
      }
      .pagination-stats-container {
        font-size: 14px;
        display: flex;
        justify-content: center;
        section {
          margin: 5px 0;
        }
      }
    }
  }
}

.loader {
  background-image: url('../img/loader.gif');
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  width: 100%;
  padding-top: 120px;
}
.highlight {
  font-weight: bold;
  font-style: normal;
}

:global .nav-link {
  background-color: #eee;
  font-size: 16px;
  &.active {
    color: #ea5d4e !important;
    font-weight: bold;
    background: none !important;
  }
}
