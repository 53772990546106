.registries-container {
  border: 2px solid #cdd7da;
  border-radius: 15px;
  padding: 20px;
  margin: 60px 0;
  h5 {
    color: #46565d;
  }
  p {
    color: #193e72;
    font-weight: bold;
  }
  .registries-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .registry {
      font-size: 12px;
      border: 1px solid #cdd7da;
      border-radius: 36px;
      padding: 5px 10px;
      min-height: 36px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: center;
      margin-bottom: 5px;
      section {
        color: #ea5d4e;
        font-weight: bold;
        padding: 0;
      }
      a {
        color: #46565d;
        padding: 0;
        max-width: 100%;
        word-wrap: break-word;
      }
      @media (min-width: 1024px) {
        width: 49%;
      }
      @media (min-width: 1400px) {
        width: 33%;
      }
    }
  }
  .button-container {
    text-align: center;
    margin-top: 15px;
    .view-more-button {
      color: #193e72;
      border: none;
      background: none;
      font-weight: bold;
      font-size: 12px;
    }
    .view-more-button:hover {
      text-decoration: underline;
    }
  }
}
