.header {
  background-color: #193e72;
  @media (min-width: 768px) {
    border-bottom-left-radius: 50% 10%;
    border-bottom-right-radius: 50% 10%;
  }
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  height: 360px;
  width: 100%;
}

.home-container {
  position: absolute;
  z-index: 2;
  text-align: center;
  width: 100%;
  margin-top: 60px;
  .home-logo {
    max-width: 100%;
    @media (min-width: 450px) {
      height: 120px;
    }
  }
  h1 {
    color: #ffffff;
    @media (min-width: 768px) {
      margin: 30px;
    }
  }
  .search-container {
    max-width: 95%;
    margin: 0 auto;
    @media (min-width: 768px) {
      width: 50%;
    }
  }
}

.home-beta {
  padding: 1rem;
  position: fixed;
  left: 0;
  width: 100%;
  top: 400px;
  z-index: -1;
}

.footer-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
