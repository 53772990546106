.modal-header {
  background-color: #193e72;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  .modal-title {
    font-weight: 700;
  }
  button {
    color: #eef2f3;
    text-shadow: none;
    background: none;
    border: none;
    font-size: 2em;
  }
  button:hover {
    color: #ffffff;
  }
}
label {
  font-weight: bold;
  color: #193e72;
}
input,
textarea {
  border: 2px solid #acbcc3;
  border-radius: 15px;
  padding: 0 5px;
}
input:focus,
textarea:focus {
  border: 2px solid #ea5d4e;
}
.form-button {
  background: #193e72;
  border: none;
  padding: 5px 15px;
  border-radius: 34px;
  color: #ffffff;
  font-weight: bold;
}
.form-button:hover {
  background: #415b81;
}
.feedback-form {
  .toggle-button-group {
    display: flex;
    .toggle-button-emoticon {
      background: none;
      color: #ea5d4e;
      border: none;
      margin: 15px;
      padding: 0;
      width: 10px;
    }
    .toggle-button-category {
      background: #193e72;
      color: #ffffff;
      padding: 15px;
      border-radius: 0;
      border: solid white 5px;
      @media (max-width: 400px) {
        padding: 5px;
      }
    }
  }
}
.confirmation-text {
  margin: 0;
  color: #193e72;
}
