.main {
    z-index: 0;
    position: relative;
    top: -10px;
    flex-grow: 1;
    flex-shrink: 0;
    margin: 0 auto;
    max-width: 100%;
    background-color: white;
    -webkit-box-shadow: 0 0 50px 25px #acbcc3;
    -moz-box-shadow: 0 0 50px 25px #acbcc3;
    box-shadow: 0 0 50px 25px #acbcc3;
    @media (min-width: 799px) {
        width: 75%;
    }
}
