.hit-item {
  margin-bottom: 30px;
}
.title a {
  color: #193e72;
  font-size: 18px;
}
.detail,
.content {
  dt,
  dd {
    display: inline;
  }
  dd:after {
    content: '\007C';
    margin: 10px;
  }
  dd:last-of-type:after {
    content: none;
  }
  dt {
    &:after {
      content: ':';
      display: inline;
    }
  }
}
.detail {
  color: #46565d;
  font-size: 13px;
  line-height: 24px;
  dt {
    font-weight: normal;
    margin-right: 7px;
  }
  dd {
    font-weight: bold;
  }
}
.content {
  font-size: 14px;
  dt {
    color: #46565d;

    margin-right: 7px;
  }
}
.flag-button {
  background-color: #ffffff;
  border: none;
  color: #eb5757;
  margin: 0;
  font-size: 11px;
  height: 30px;
  width: 30px;
  font-weight: bold;
  margin-left: 20px;
}

.meta-container {
  align-items: flex-start;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  padding-top: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.download-button-container {
  margin-bottom: 5px;
  button {
    background-color: white;
    border: none;
    color: #193e72;
    margin: 0 0 15px auto;
    font-size: 17px;
    font-weight: bold;
    display: block;

    &[disabled] {
      color: #acbcc3;
      cursor: help;
    }
  }
}
