.banner {
  flex-shrink: 0;
  background-color: #fadedb;
  position: relative;
  bottom: -10px;
  .banner-container {
    height: 100px;
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    padding: 15px 30px;

    .banner-image--nihrio {
      height: 40px;
    }
    .banner-image--nu {
      height: 50px;
    }

    @media screen and (max-width: 550px) {
      height: 70px;
      padding: 15px;

      .banner-image--nihrio {
        height: 25px;
      }
      .banner-image--nu {
        height: 30px;
      }
    }
  }
}

.footer {
  z-index: 1;
  flex-shrink: 0;
  background-color: #193e72;
  color: #ffffff;
  padding: 30px 0;
  height: 84px;
  display: flex;
  justify-content: space-around;
  .footer-links {
    margin: 0;
    padding: 0;
    display: inline-flex;
    gap: 10px;

    li {
      display: inline-block;
      .footer-link {
        color: #ffffff;
      }
    }
  }
  .feedback-button {
    background: none;
    border: none;
    color: #ffffff;
  }
  .feedback-button:hover {
    text-decoration: underline;
  }
}
