@import 'forms.scss';

.search-form-container {
    background: #ffffff;
    border-radius: 58px;
    padding: 10px;
    transition: 2s ease;
    .search-form {
        margin: 0 auto;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        .search-input-group {
            background-color: #eef2f3;
            color: #bdbdbd;
            border: none;
            border-radius: 38px;
            width: 80%;
            height: 38px;
            display: inline-flex;
            margin-right: 10px;
            .search-icon {
                color: #ea5d4e;
                margin: 10px;
            }
            .input-sizer {
                @include vertical-input-sizer();
                width: 100%;
                min-height: 38px;

                .search-input {
                    width: 100%;
                    min-height: 38px;
                    max-height: 200px;

                    border: none;
                    background-color: #eef2f3;
                    padding: .375rem .75rem;
                    font-size: 16px;
                    z-index: 10;

                    &:focus-visible {
                        outline: none;
                    }
                }

                &::after {
                    font-size: 16px;
                    padding: .375rem .75rem;
                }
            }
            .info-button {
                background-color: #eef2f3;
                border: none;
                padding: 0 5px;
                &:hover,
                &:active,
                &[aria-expanded='true'] {
                    background-color: #bccace !important;
                }
                border-radius: 0 38px 38px 0;
            }
        }
    }
    .search-info-text {
        margin-top: 15px;
        text-align: left;
        font-size: 14px;
        mark {
            background: none;
            color: #ea5d4e;
        }
        .symbol {
            font-weight: bold;
            font-size: 16px;
            font-family: sans-serif;
        }
    }
    .search-button {
        background-color: #ea5d4e;
        color: #ffffff;
        border: none;
        height: 38px;
        width: 20%;
        min-width: 74px;
        padding: 0 15px;
        font-weight: bold;
        border-radius: 38px;
    }
    .search-button:hover {
        background-color: #e8746a;
    }
    .filters-button {
        padding: 10px;
        text-transform: uppercase;
        color: #acbcc3;
        font-weight: bold;
        font-size: 12px;
        cursor: pointer;
        display: block;
        @media (max-width: 363px) {
            margin-top: 10px;
        }
        @media (min-width: 650px) {
            display: none;
        }
    }
}

.search-form-open {
    @extend .search-form-container;
    transition: 0s;
    height: 100%;
    border-radius: 27px;
}
